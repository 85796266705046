import React from "react"

import Layout from "../components/baseLayout"
import { FaGithub } from 'react-icons/fa'
import SEO from "../components/seo"

const AboutPage = () => {
    const email = "hello@yhgoh.com"
    return (
  <Layout>
    <SEO title="About me" />
        <section id="primary" className="container main_content_area">
        {/* start content */}
        
        <div className="row full_width_page_single no_sidebar_post_single"><div className="col12">
        <article className="blog_page_container post-180 page type-page status-publish">
            <div className="single_page_body">
            <div className="post_header post_header_single">
                <h1 className="entry-title title post_title section_title">Hi. I'm Yong Hua</h1>
                <span className="social_media">
                    <a href="https://github.com/gyonghua">
                    <FaGithub />
                    </a>
                </span>
            </div>
            </div>

            <div className="page_body">
            <div className="post_info_wrapper">
            <div className="entry-content blog_page_text blog_page_description">
                <p>
                    I write code, think about data and create digital experiences. 
                    You can reach me at <span className="email">{email}</span>
                </p>

            </div>
            </div>
            </div>
        </article>
        </div></div> {/* end content */}
        </section>
  </Layout>
    )
}

export default AboutPage


